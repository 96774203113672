.campaign-popup-custom-textfield input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #303030;
  box-shadow: 1px 1px 1px 0px #0000004D;
  color: #f0f0f0;
  font-family: 'Honeywell Sans Web';
  font-size: 14px;
  border-radius: 4px;
  height: 38px;
}

.campaign-popup-custom-textfield .MuiFormHelperText-sizeMedium {
  color: white;
  margin: 0;
}