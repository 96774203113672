* {
  box-sizing: border-box;
}

.reports-template > .MuiBox-root > #simple-tabpanel-0 > .MuiBox-root {
  padding-left: 0;
}

.reports-template > .MuiBox-root > #simple-tabpanel-1 > .MuiBox-root {
  padding-left: 0;
}

#reportTemplateDetailsContainer {
  font-family: Honeywell Sans Web;
  color: #F0F0F0;
  background-color: #313131;
  height: 100%;
}

#reportTemplateDetailsHeading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 2.5px;
  text-align: left;
  padding: 0.9em 0.5em;
  text-transform: uppercase;
  margin-left: 1rem;
}

#reportTemplatMinoreDetailsContainer {
  background-color: #272727;
  border-radius: 10px;
  border: 1px solid #101010;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
}

.report-details-info-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-right: 10rem;
  font-size: 20px;
}

.report-info-title {
  font-size: 16px;
  font-weight: 500;
}

table, tr, td, th {
  border-collapse: collapse;
}

#reportTemplateDetailsContainer {
  font-family: Honeywell Sans Web;
  color: #F0F0F0;
  background-color: #313131;
  height: 100%;
}

#report-content-details-table::-webkit-scrollbar-thumb {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

#report-content-details-table::-webkit-scrollbar-track {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.crossIcon:after{
  display: inline-block;
  content: "\00d7";
}

.plusIcon::after {
  display: inline-block;
  content: "\002b";
}

#plusCell {
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 9px;
  border-top: 2px solid #202020;
  border-right: 2px solid #202020;
  border-bottom: 2px solid #202020;
}
