* {
  box-sizing: border-box;
}

body {
  font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
  overscroll-behavior: none;
}

/* Classes for rounded scuf componenets */

/* Use className="rounded" for a rounded card, 10px is used according to figma designs */
.ui.raised.card.rounded {
  border-radius: 10px !important;
}

/* Use className="rounded" for a rounded buttons, 4px is used according to figma designs */
.ui.button.rounded {
  border-radius: 4px !important;
}

.ui.single-input.rounded .input-box {
  border-radius: 4px !important;
}

/* Asset Monitor Dropdown */
 #seleceDropDownTopUtilityBar {
  margin-left: 1em;
  padding-left: 1em;
  border-left: 1px solid white;
 }

/* Asset monitor Risk Matrix */

.flex-container {
  padding-top: 2em;
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  font-size: 16px;
}

/* Asset monitor Risk Matrix Heatmap*/

.tableLabels {
  text-align: center;
  font-size: 1em;
}

#assetHMlabels {
  font-size: 1.2em;
  text-align: center;
  padding-top: 20px;
}

.riskMatrixBtn {
  width: 81px;
  height: 40px;
  font-size: 1.2em;
  cursor: pointer;
}

#mainHeatMapContainer {
  width: 100%;
}

.rotated {
  rotate: 270deg;
  transform-origin: 63% 20%;
  font-size: 1.2em;
}
.deviceList{
  color: black;
}

@media only screen and (max-width: 1532px) {
  .riskMatrixBtn {
    width: 70px;
    height: 35px;
    font-size: 1em;
  }
  .tableLabels {
    font-size: 12px;
  }
  #assetHMlabels {
    font-size: 1em;
    text-align: center;
    padding-top: 15px;
  }

  .rotated {
    rotate: 270deg;
    transform-origin: 63% 20%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1368px) {
  .riskMatrixBtn {
    width: 60px;
    height: 30px;
    font-size: 0.8em;
  }
  .tableLabels {
    font-size: 12px;
  }
  #assetHMlabels {
    font-size: 1em;
    text-align: center;
    padding-top: 10px;
  }
  .rotated {
    rotate: 270deg;
    transform-origin: 63% 20%;
    font-size: 1em;
  }
}

.riskMatrixRed {
  background-color: red;
  color: white;
  border: none;
  border-radius: 2px;
}
.riskMatrixRedPressed {
  background-color: red;
  color: white;
  border: none;
  border-radius: 2px;
  transform: scale(0.80);
  transition-duration: 0.3s;
}

.riskMatrixWhite {
  background-color: white;
  color: black;
  border: none;
  border-radius: 2px;
}
.riskMatrixWhitePressed {
  background-color: white;
  color: black;
  border: none;
  border-radius: 2px;
  transform: scale(0.80);
  transition-duration: 0.3s;
}

.riskMatrixF69791 {
  background-color: #f69791;
  color: black;
  border: none;
  border-radius: 2px;
}
.riskMatrixF69791Pressed {
  background-color: #f69791;
  color: black;
  border: none;
  border-radius: 2px;
  transform: scale(0.80);
  transition-duration: 0.3s;
}

.riskMatrixFACBC8 {
    background-color: #facbc8;
    color: black;
    border: none;
    border-radius: 2px;

}
.riskMatrixFACBC8Pressed {
  background-color: #facbc8;
  color: black;
  border: none;
  border-radius: 2px;
  transform: scale(0.80);
  transition-duration: 0.3s;
}

/* Channel Data */

.dropdownMenu {
  height: 2.5rem;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.375rem;
  color: #e0e0e0;
  transition: border-color 150ms ease-in;
  border: #707070 0.0625rem solid;
  border-radius: 0;
  width: 8rem;
  background: transparent;
  padding: 0.6875rem 0.4375rem;
}

.dropdownMenu:focus {
  border-color: #57c5f7;
}

.dropdownMenu:hover {
  cursor: pointer;
}

select > option {
  height: 2.5rem;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.375rem;
  color: #e0e0e0;
  width: 8rem;
  background: #303030;
  padding: 0.6875rem 0.4375rem;
}

/* AMI cards*/

.defaultCards {
  cursor: pointer;
  background-color: #272727 !important;
  color: #F0F0F0 !important;
  width: 275px;
}

.defaultCardBorder {
  cursor: pointer !important;
  border-left: #57c5f7 5px solid !important;
}