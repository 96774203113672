.App {
    color: black;
    margin: 0;
    background-image: url("./assets/background.svg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.loginComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loginCard {
    font-size: 18px;
    background-color: white;
    width: 30vw;
    height: auto;
    padding: 70px 50px;
    border-radius: 10px;
}

form {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

input {
    font-size: 13px;
    padding: 4px;
    width: 100%;
    height: 40px;
    background: #F0F0F0;
    border-radius: 4px;
    border-color: #F0F0F0;
}

input::placeholder {
    font-style: italic;
}


button.loginElement {
    color: #fff;
    height: 40px;
    background: #0072C2;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 0px;
}

button.loginElement:hover {
    cursor: pointer;
}

.loginElement {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

a.loginElement {
    color: #005EAC;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

span.loginElement {
    padding-right: 40px;
}

#login-footer {
    text-align: center;
}

.password-container {
    position: relative;
}

.password-container input[type="password"],
.password-container input[type="text"] {
    width: 100%;
    box-sizing: border-box;
}

.eye {
    position: absolute;
    top: 28%;
    right: 2%;
    cursor: pointer;
}


/* 1024 - 1500 */

@media screen and (max-width: 1500px) and (min-width: 1024px) {
    .loginCard {
        width: 30vw;
    }

    .loginElement {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .loginCard {
        font-size: 16px;
        background-color: white;
        width: 30vw;
        height: auto;
        padding: 40px 28px;
        border-radius: 10px;
    }

    input {
        font-size: 11px;
        padding: 4px;
        width: 100%;
        height: 30px;
        background: #F0F0F0;
        border-radius: 4px;
        border-color: #F0F0F0;
    }

    button.loginElement {
        color: #fff;
        font-size: 11px;
        height: 40px;
        background: #0072C2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 0px;
    }

    .loginElement {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    a.loginElement {
        color: #005EAC;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    span.loginElement {
        padding-right: 40px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .loginCard {
        width: 60vw;
    }

    .loginElement {
        margin-bottom: 12px;
        text-align: center;
        width: 100%;
    }

    .loginCard {
        font-size: 16px;
        background-color: white;
        width: 40vw;
        height: auto;
        padding: 40px 20px;
        border-radius: 10px;
    }

    input {
        font-size: 11px;
        padding: 4px;
        width: 100%;
        height: 30px;
        background: #F0F0F0;
        border-radius: 4px;
        border-color: #F0F0F0;
    }

    button.loginElement {
        color: #fff;
        font-size: 11px;
        height: 40px;
        background: #0072C2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 0px;
    }

    .loginElement {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    a.loginElement {
        color: #005EAC;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    span.loginElement {
        padding-right: 40px;
    }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
    .loginCard {
        width: 70vw;
        padding: 60px 28px;
        font-size: 16px;
    }

    .loginElement {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .eye {
        position: absolute;
        top: 28%;
        right: 4%;
        cursor: pointer;
    }

    input {
        padding: 4px;
        width: 100%;
        height: 35px;
        background: #F0F0F0;
        border-radius: 4px;
        border-color: #F0F0F0;
    }

    button.loginElement {
        color: #fff;
        height: 35px;
        background: #0072C2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 0px;
    }

    a.loginElement {
        color: #005EAC;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }

    span.loginElement {
        padding-right: 40px;
    }
}

@media screen and (max-width: 480px) and (min-width: 360px) {
    .loginCard {
        width: 80vw;
        padding: 40px 20px;
        font-size: 16px;
    }

    .loginElement {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .eye {
        position: absolute;
        top: 28%;
        right: 4%;
        cursor: pointer;
    }

    input {
        font-size: 13px;
        padding: 4px;
        width: 100%;
        height: 40px;
        background: #F0F0F0;
        border-radius: 4px;
        border-color: #F0F0F0;
    }

    button.loginElement {
        color: #fff;
        height: 40px;
        background: #0072C2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 0px;
    }

    a.loginElement {
        color: #005EAC;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    span.loginElement {
        padding-right: 40px;
    }
}

@media screen and (max-width: 360px) and (min-width: 250px) {
    .loginCard {
        width: 90vw;
        padding: 40px 15px;
        font-size: 12px;
    }

    .loginElement {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .eye {
        position: absolute;
        top: 28%;
        right: 4%;
        cursor: pointer;
    }

    input {
        font-size: 13px;
        padding: 4px;
        width: 100%;
        height: 40px;
        background: #F0F0F0;
        border-radius: 4px;
        border-color: #F0F0F0;
    }

    button.loginElement {
        color: #fff;
        height: 40px;
        background: #0072C2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 0px;
    }

    a.loginElement {
        color: #005EAC;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    span.loginElement {
        padding-right: 40px;
    }
}