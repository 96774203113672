input#scheduler-name {
  border: 1px solid #ffffff54;
  color: #fff;
}

span.error-upload-message {
    color: #e13131;
    font-size: 13px;
}

.MuiMultiSectionDigitalClockSection-item {
  color: #fff !important;
}

.searchSection {
  padding: 0px 0px;
}

button.clear-btn {
  background-color: transparent !important;
  color: #64c3ff !important;
  margin-top: 0px !important;
  margin-left: 50px !important;
}

.scheduler-grid button.filter-btn {
  height: 40px !important;
}

.configuration-name-class input{
  border: 1px solid #ffffff54;
  color: #fff;
}

.box {
  border: 1px solid #505050;
  padding: 1px 6px 0px 6px;
  border-radius: 5px;
  margin-left: 7px;
}

.history-box {
  border: 1px solid #1a1818;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#tableTitle {
  background-color: #353535;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.history-table-box {
  padding: 0px 20px 10px 20px;
  margin-top: -20px;
}

.radio-box {
  float: left;
}

.Mui-checked {
  color: #64C3FF !important;
}

input#Configuration-name {
  color: #fff;
  border: 1px solid #ffffff61;
  -webkit-text-fill-color: #fff;
}

input#scheduler-name {
  color: #fff;
  border: 1px solid #ffffff61;
  -webkit-text-fill-color: #fff;
}

.errorCompCampaign {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
  background-color: #494d49;
  padding: 40px;
}

span.more-info {
  margin-left: 15px;
  margin-top: 1px;
  position: absolute;
}

.more-info-tooltip .MuiTooltip-arrow {
  color: #f5f5f9;
}

.time-picker-Scheduler {
  border: 1px solid #afa1a161 !important;
  border-radius: 5px;
}

.apiErrWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px;
}
