#top-right-config-btn {
  background-color: #64c3ff;
  height: 30px;
  border-radius: 4px;
  margin-left: 10px;
  border: none;
  font-weight: bold;
}

#top-right-config-btn:hover {
  cursor: pointer;
}

.configurations-table-heading {
  width: 99%;
  color: #F0F0F0;
  padding: 1em;
  background-color: #303030;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border: 1px solid black; */
  font-weight: 500;
}

.config-list-box {
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid #787878;
}

.link-breadcrumb {
  color: #dbdbdb;
  padding-top: 1em;
  padding-left: 1.3em;
}

.assign-list-footer {
  width: 99%;
  border-top: 1px solid black;
  font-weight: 500;
  background-color: #272727;
}