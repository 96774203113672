.custom-search-box {
  width: 360px;
  padding: 0px;
  border: none;
  position: relative;
  padding-right: 28px;
}

.custom-search-box-component:focus, .custom-search-box:focus, .search-box-input:focus {
  outline: none;
}

.search-cross-bar {
  position: absolute;
  top: 7px;
  right: 32px;
  cursor: pointer;
  color: #D0D0D0;
}

.search-cross-bar:hover {
  color: #64C3FF;
}

.search-box-icon-img img {
  position: absolute;
}

.search-icon {
  padding: 12px 10px 10px 10px;
}

.search-box-input::placeholder {
  color: #D0D0D0;
  font-family: 'Honeywell Sans Web';
  font-weight: 500;
  font-size: 14px;
}

.search-box-input {
  background-color: #505050;
  color: #D0D0D0;
  font-family: 'Honeywell Sans Web';
  font-weight: 500;
  font-size: 14px;
  padding-left: 30px;
  border: none;
  padding-right: 35px;
  height: 36px;
}

.search-box-input:focus {
  border: 1px solid #64C3FF;
}