.button_active{
    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    border-radius: 4px;
    background:  #64C3FF;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.30);
}

.button_logout{
    border-radius: 4px;
    background:  #272727;
    box-shadow: 0px 11px 15px -2px rgba(0, 0, 0, 0.25), 15px 30px 40px 0px rgba(0, 0, 0, 0.40);    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
}


#autologout-h2{
    color: white;
}

#autologout-p{
    color: white;
}