
.card-body{
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
  height:100%;
}
.card-body .icon-navigate {
  color: #f0f0f0;
  left: 3.49%;
  right: 96.15%;
  top: 9.84%;
  bottom: 88.84%;

}

.card-body .header-title {
  height: 0px;
  left: 83px;
  top: 80px !important;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  color: #f0f0f0;
  padding-top:19px !important;

}
.header-title {
  padding-top: 0px !important;
  margin-top: -10px !important;
}
.card-body .container {
  padding-left: 9px;
  padding-top: 24px;
  background: #272727;
  border: 0.5px #101010;
  border-radius: 10px;
  overflow-x: 'hidden';
  overflow-y: 'scroll';
  box-shadow: 1px 1px 1px 1px #101010;
  box-sizing:border-box;
  width:100%;
  height:100% !important;
  display:grid;
  gap:1rem;
  padding-right:14px;
  padding-bottom:24px;
}

.card-body .cardActionArea {
  background-color: #272727;
}

.card-body .cardMainTitle {
  font-family: 'Honeywell Sans Web';
  letter-spacing: 2.5px;
  font-weight: 400;
  padding-top:24px;
}

.card-body ::marker {
  font-size: 24px;
  color: #7d7d7d;
}

.card-body .MuiCard-root {
  border-radius: 0px !important;
  /* max-width: 431px !important; */
  box-shadow: unset !important;
  background: transparent !important;
  padding-left:  6px;
  padding-bottom: 6px;
}

.card-body .MuiGrid-root {
  margin-left: 0px;
  margin-top: -0.8%;
}

.card-body .MuiToolbar-root {
  position: relative;
  top: -24px;
}

.card-body .MuiButtonBase-root {
  padding-right: 13px;
  margin-right: 0px;
  padding-bottom: 20px;
}

.card-body .MuiGrid-item {
  padding-left: 6px !important;
  padding-top: 0px !important;
}

.card-body .MuiTypography-root {
  /* padding-top: 24px; */
  padding-left: 24px;
  /* padding-bottom: 12px; */
  font-family: "Honeywell Sans Web";

}



.card-body .MuiCardContent-root {
  padding-top: 16px;
  padding-left: 16px;

}

.card-body .MuiGrid-container {
  width: 99%;
  height: 84%;

}

.card-body .cardcontent {
  background: #404040;
  border-radius: 10px;
  color: #f0f0f0;
  padding: 0px;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding-bottom:12px !important;
  min-height:175px;
}

.card-body .dm-backButton .MuiButtonBase-root {
  padding: 0 !important;
  margin: 0 !important;
}


.pr0 {
  padding-right: 0px;
}

.card-body .dm-backButton .MuiButtonBase-root svg {
  margin-left: -28px;
  margin-top: 44px;
  font-size: 2rem;
}

.card-body .dm-backButton .MuiTypography-root {
  padding-left: 0px;
  margin-left: -20px;
}

@media (min-width: 1200px) {
  .card-body .MuiGrid-root {
    flex-basis: 24%;
  }
}

.sub-titleList li {
  padding-left: 6px;
  margin-bottom: 0px;
  margin-left: -20px;
  line-height: 20px;
  font-family: 'Honeywell Sans Web';
  font-size: 14px;
}

.assetmgmtli:hover {
  color: #64C3FF;
  cursor: pointer;
}

/* Modal popup css-----------------*/

.MainModal {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: 600;
  height: 420;
  background-color: '#302e2e';
  border: '1px #302e2e';
  box-shadow: "5px 5px 5px 5px #141111";
  padding: 4px;
  padding-left: '5%';
  padding-right: '5%';
  border-radius: '6px';
}

.input-file {
  display: none;
  align-items: center !important;
  text-align: center;
}

.file-label {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 10px;
  margin-left: 125px;
  margin-top: 9px;
  width: 143px;
  height: 28px;
  background: #272727;
  border: 1.5px solid #64c3ff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  flex: none !important;
  order: 1 !important;
  flex-grow: 0 !important;
}

.draganddroptext {
  width: 175px;
  height: 48px;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 24px !important;
  display: flex !important;
  flex-direction: row !;
  align-items: center;
  text-align: center;
  color: #b0b0b0 !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  font-family: 'Honeywell Sans Web' !important;
  margin-left: 125px !important;
}
.max-width{
  width: max-content !important;
}
.Cancelbutton.device-list-button{
  right: 31% !important;
}
.Cancelbutton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 97px;
  height: 32px;
  font-family: 'Honeywell Sans Web';
  font-style: normal;
  font-size: 14px;
  background: #272727;
  border: 1.5px solid #64c3ff;
  border-radius: 4px;
  color: rgb(126, 158, 196);
  padding: 7px 22px;
  letter-spacing: 0.5px;
  position: fixed;
  cursor: pointer;
  bottom: 17px;
  right: 145px;
}

.Confirmbutton {
  padding: 6px 24px;
  border: 0px;
  gap: 8px;
  box-sizing: border-box;
  font-family: "Honeywell Sans Web";
  width: 108px;
  height: 32px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #303030;
  margin-bottom: 18px;
  box-shadow: 1px 1px rgba(0,0,0,0.3);
  position: fixed;
  bottom: -1px;
  right: 32px;
  border-radius: 4px;
  text-transform: capitalize
}
.Confirmbutton.blue-bg{
  background-color: #64C3FF;
  cursor: pointer;

}
.Confirmbutton.grey-bg{
 background-color: #6f7070;
 cursor: not-allowed;
}



.css-5xe99f-MuiLinearProgress-bar1 {
  
  background: linear-gradient(270deg, #FF644C 0%, #1792E5 100%) !important;
}
.linearprogressbar{
  width: 100%;
  border-radius: 4px !important;
  height: 6px !important;
  margin-top: 3%;
  position: relative;
  overflow: hidden;
  display: block;
  -webkit-transition: -webkit-transform .4s linear;
  transition: transform .20s linear;
  transform-origin: left;
  z-index: 0;
  background-color: rgb(167, 202, 237);

}

.subtext1 {
  width: 214px;
  height: 32px;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center;
  color: #b0b0b0;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  padding-top: 4%;
  padding-bottom: 1%;
  font-family: 'Honeywell Sans Web' !important;
  margin-left: 93px !important;
}

.buttonelement {
  bottom: 0;
  right: 10px;
  position: fixed;
}

.card-body .MaximizeIcon {
  color: #a4a5a7;
  padding-top: 1%;
  margin-top: -4%;
  margin-left: 93%;
}

.closeFullscreenIcon {
  color: #a4a5a7;
  margin-top: -1%;
  float: right;
  right: 20px;
  position: absolute;}

.DescriptionOutlinedIcon {
  color: #a4a5a7;
  margin-top: 25px;
  font-size: xx-large !important;
  margin-left: 48%;
}



.DltIcon {
  margin-left: 97%;
  margin-top: -7%;
  margin-bottom: 14%;
  color: #a4a5a7;
  font-size: 20px !important ;
}

.modal-box {
  border: 3px dashed #404040;
  padding-bottom: 2%;
  width: 400px;
  height: 238px;
}

@media screen and (max-width: 600px) {
  .pop-up-modal {
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    width: 66% !important;
    padding-left: 5%;
    margin-left: 24%;
  }

  .dragtext1 {
    margin-top: 2%;
  }

  .button1 {
    height: 10%;
  }

  .button2 {
    height: 10%;
  }

  .draganddroptext {
    margin-left: 32% !important;
    align-items: center;
  }

  .title {
    padding-bottom: -14px !important;
  }
}

@media screen and (max-width: 900px) {
  .cardcontent {
    border-radius: 10px;
  }

  .label {
    width: 66% !important;
    padding-left: 5%;
    margin-left: 24%;
  }

  .title {
    padding-bottom: -14px !important;
  }
}

@media(min-width:600px){
  .container{
    grid-template-columns: repeat(2, 1fr);
    
  }
  
  
}
@media(min-width:900px){
  .container{
    grid-template-columns: repeat(3, 1fr);
    
  }
}
@media(min-width:1200px){
  .container{
    grid-template-columns: repeat(4, 1fr);
    
  }
  .card-body{
    height:100%;
  }
}
