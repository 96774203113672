.login-layout{
    width: 100vw;
    height: 100vh;
    background-image: url('./../../assets/login_background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3%;
    padding-right: 3%;
}

.login-card{
    max-width: 450px !important;
    padding: 2.5% !important;
}

.login-form{
    display: flex;
    flex-direction: column;
}

.login-form-logo{
    max-width: 200px;
}

.honeywell-privacy-statement {
    margin-top: 2em;
    text-align: center;
    font-size: 12px;
}

.honeywell-privacy-statement p, a {
    margin: 0;
}