.custom-list-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.custom-list-ul li {
  padding: 0.7rem;
  color: #D0D0D0;
  margin-bottom: 4px;
  margin-right: 5px;
  border: 1px solid #5E5B5B;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  transition: ease-out 0.2s;
}

.custom-list-ul li:hover {
  padding: 0.9rem;
  color: #64C3FF
}