.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-modal-button-container {
    text-align: right;
    margin-top: 30px;
}

.state-button-container.state-update {
    height: 72px;
    padding: 0px;
}

button.update-btn {
    float: right;
}

.btn-asset {
    color: #000 !important;
}

.Edit-AssetSummary-Modal, .state-update-modal{
    backdrop-filter: blur(3px);
    background: #20202080;
}

#modal-modal-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    font-family: "Honeywell Sans Web";
}

.list {
    background-color: #645e5e;
    height: 130px;
    overflow: scroll;
    position: absolute;
    width: 100%;
    padding: 0px;
}

.tag-Update {
    position: relative;
}

.list .list-item {
    color: #fff;
    padding: 8px 15px;
    cursor: pointer;
}

.list .list-item:hover {
    background-color: #5e7da140;
    color: #9ac9ff;
}

.stateHistory-table td, 
.stateHistory-table th {
    padding: 10px;
    text-align: left;
}