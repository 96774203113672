#custom-textarea {
  padding: 10px;
  border-radius: 4px;
  border: none;
  color: #f0f0f0;
  background-color: rgb(64, 64, 64);
}

#custom-textarea:focus, #custom-textarea:active {
  border: 2px solid #64C3FF;
  outline: none;
}