.unassign-group-table {
    margin: 40px 0px 20px 0px;
    height: max-content;
    overflow-x: scroll;
  }
  
  .unassign-group-table td.MuiTableCell-root {
    padding: 8px !important;
    vertical-align: middle !important;
  }
  
  .group-search .custom-search-box {
    padding-right: 0px;
  }
  
  .group-search .custom-search-box-component{
  margin: 0px;
  }
  
  .group-search {
      margin-top: 30px;
  }
  
  .group-search .search-cross-bar {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
  }
  
  .unassign-group-table {
    background-color: #202020;
  }
  
  .unassign-group-table .ui.striped.table, 
  .unassign-group-table .ui.striped.table tbody tr {
      background-color: #202020;
      border: 0px;
  }