.add-firmware-container {
    font-family: "Honeywell Sans Web";

    .required {
        color: #EE3426
    }

    .errorColor {
        color: #EE3426;
    }
    .title {
        margin-bottom: 24px;
        line-height: 24px;
        font-weight: 800;
    }

    .drag-drop-container {
        border: 2px dashed #505050;
        padding: 20px 0px;
        margin-top: 10px;

        .upload-file-name {
            padding: 0 15px;
        }

        .modal-box-firmware {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
        }

        .draganddroptext-firmware {
            font-size: 16px;
            line-height: 24px;
            margin-left: 8px;
            color: #B0B0B0;
            font-family: "Honeywell Sans Web";
        }

        .DescriptionOutlinedIcon {
            margin-top: 0;
            margin-left: 0;
        }

        .file-label-firmware {
            border: 1.5px solid #64C3FF;
            border-radius: 4px;
            padding: 3px 8px;
            margin-left: 8px;
            color: #64C3FF;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .subtext-firmware {
        font-size: 12px;
        color: #B0B0B0;
        margin-top: 5px;
        margin-bottom: 35px;
        font-family: "Honeywell Sans Web";
    }

    .MuiInputBase-root {
        height: 32px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: transparent;
    }

    .MuiFormControl-root {
        border: none;
        margin-top: 10px;
    }

    .MuiSelect-select {
        color: #f0f0f0;
        font-family: "Honeywell Sans Web";


    }

    .MuiTextField-root {
        .MuiInputBase-input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            background-color: #303030;
            box-shadow: 1px 1px 1px 0px #0000004D;
            color: #f0f0f0;
            font-family: 'Honeywell Sans Web';
            font-size: 14px;
            border-radius: 4px;
            height: 32px;
            width: 236px;
        }

        .MuiInputBase-root {
            width: max-content;
        }

        .MuiFormLabel-root {
            top: -10px;
            color: #f0f0f0;
            padding-left: 3px;
        }

    }

    .MuiInputBase-root::before {
        border-bottom: none !important;
    }

    .MuiSelect-icon {
        color: #f0f0f0;
    }
}

span.requiredDropDown{
    position: absolute;
    margin-left: 68px;
    margin-top: 10px;
    width: max-content;
    color: #EE3426;
}

span.requiredAssetType{
    position: absolute;
    margin-left: 78px;
    margin-top: 10px;
    width: max-content;
    color: #EE3426;
}

span.requiredFmwType{
    position: absolute;
    margin-left: 106px;
    margin-top: 10px;
    width: max-content;
    color: #EE3426;
}

.footer {
    background: #272727;
    height: auto;
    padding: 15px 30px;
    margin: 0px -15px;
    display: flex;
    font-family: "Honeywell Sans Web";
    justify-content: space-between;

    .MuiButtonBase-root:first-child {
        color: #64C3FF !important;
    }

    .MuiButtonBase-root {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        &:nth-child(2) {
            background-color: #64C3FF;
            border-radius: 4px;
            color: #303030 !important;

        }
    }
}

.add-firmware-modal {
    background-color: transparent;
    font-family: "Honeywell Sans Web";
    color: #f0f0f0;
    box-shadow: none;
    height: auto;
    .MuiBox-root {
        box-shadow: none;
        padding: 40px;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 11px 15px -2px rgba(0, 0, 0, 0.25);
    }
.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    #modal-modal-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        font-family: "Honeywell Sans Web";

    }
    #modal-modal-description {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        font-family: "Honeywell Sans Web";

        line-height: 24px;

    }
    .add-modal-button-container{

        margin-top: 45px;
        display: flex;
        justify-content: flex-end;

        .MuiButtonBase-root:first-child {
            color: #64C3FF !important;
            font-family: "Honeywell Sans Web";
            border: 1.5px solid #64C3FF;
            margin-right: 10px;
        }
        .MuiButtonBase-root {
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            font-family: "Honeywell Sans Web";
            padding:5px 20px;
            &:nth-child(2) {
                background-color: #64C3FF;
                color: #303030 !important;
                border-radius: 4px;
font-family: Honeywell Sans Web;
font-size: 14px;
font-weight: 800;
line-height: 20px;
letter-spacing: 0.5px;
text-align: center;

            }
        }
    }
}

.add-firmware-modal{
    backdrop-filter: blur(3px);
    background: #20202080;

}