.toggle-container {
  width: fit-content;
  background-color: #505050;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  padding: 3px;
  height: 39px;
}

.dialog-button {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #F0F0F0;
  padding: 8px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}

.unselected {
  background-color: #505050;
  box-shadow: none;
  transition: all 0.3s ease;
}

.selected {
  background-color: #303030;
  transition: all 0.3s ease;
}
