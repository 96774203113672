
.import-button button {
    background-color: #64C3FF;
    color: #000000 !important;
    font-weight: 700;
    font-family: "Honeywell Sans Web";
    text-transform: capitalize;
}
.import-section {
    padding: 10px 10px;
    background-color: #272727;
    margin-right: 10px;
}

.devices .MuiPaginationItem-circular {
  color: #fff;
  font-weight: 200;
}

.import-button button:hover {
  background-color: #64C3FF;
}

li.MuiBreadcrumbs-li, .fileExport{
  cursor: pointer;
}

.type-name.status {
  margin-top: 30px;
}

.filterDownloadSection {
    background-color: #303030;
    border: 1px solid black;
    padding: 10px 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.filter-drawer .MuiDrawer-paper {
    background: rgba(39, 39, 39, 1);
    padding: 32px;
    font-family: "Honeywell Sans Web";
    width: 351px;
   
    .title {
      color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Honeywell Sans Web;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px;
      /* 150% */
      letter-spacing: 2.5px;
      text-transform: uppercase;
      margin-bottom: 24px;
    }
  
    .type-name {
      color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
      font-feature-settings: 'clig' off, 'liga' off;
  
      /* Body/ Body 1 (Bold) */
      font-family: Honeywell Sans Web;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 150% */
      margin-bottom: 8px;
    }
  
    .MuiButtonBase-root {
      color: rgba(55, 163, 248, 1) !important;
    }
  
    .MuiFormControlLabel-label {
      color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
      font-feature-settings: 'clig' off, 'liga' off;
  
      /* Body/ Body 1 */
      font-family: Honeywell Sans Web;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }
  
    .MuiFormControlLabel-root {
      margin-left: 0px;
    }
  
    .MuiCheckbox-root,
    .MuiRadio-root {
      padding: 8px 8px 8px 0px;
    }
  
    .date-picker {
      width: max-content;
      margin-left: 30px;
     
  .Mui-disabled{
    cursor: not-allowed;
  }
      .MuiOutlinedInput-input {
        padding: 8.5px 8px 8.5px 16px;
        -webkit-text-fill-color: #d0d0d0 !important;
      }
  
      .MuiButtonBase-root {
        color: #D0D0D0 !important;
      }
    }
  
    .divider {
      margin: 16px -32px;
      border: 1px solid var(--greyscale-grey-n-6202020, #202020)
    }
    .cancel {
      display: flex;
      padding: 6px 10px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-weight: 700;
      font-family: Honeywell Sans Web;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: none; 
      color: #64C3FF;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  .MuiInputLabel-formControl{
    color: #d0d0d0;
  }
  
  .reset-filter{
    color: var(--typography-dark-blue-64-c-3-ff, #64C3FF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    
    /* Buttons & Links/Secondary & Tertiary - Regular & Small */
    font-family: Honeywell Sans Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;}
  
    .buttons-container {
      display: flex;
      justify-content: space-between;
      text-align: center;
      text-transform: capitalize;
  margin-top: 8px;
  /* // position: absolute; */
  bottom: 20px;
      .cancel {
        display: flex;
        padding: 6px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-weight: 700;
        font-family: Honeywell Sans Web;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: none; 
        color: #64C3FF;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
      
      .confirm {
        border-radius: 4px;
        background: var(--component-dark-buttons-regular-primary-outline, #64C3FF);
        text-align: center;
        /* Buttons & Links/Primary - Regular & Small */
        font-family: Honeywell Sans Web;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 0.5px;
  
  text-transform: none;      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.30);
        color: #303030 !important;
        display: flex;
        padding: 6px 30px !important;
        justify-content: center;
        align-items: center;
        width: auto;
      }
    }
    
  }
  .filter-btn{
    border-radius: 4px !important;
  border: 1px solid var(--greyscale-grey-n-1707070, #707070) !important;
  background: var(--greyscale-grey-n-1707070, #707070) !important;
  font-family: Honeywell Sans Web !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize !important;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.30) !important;
  display: inline-flex;
  padding: 4px 24px 4px 16px !important;
  justify-content: center;
  height: 36px;
  align-items: center;
  gap: 8px;
  width: fit-content;
  }

  /********** Search Button CSS ********/
.searchBox {
    background: #505050;
    width: 330px;
    height: 36px;
    border: none;
    border-radius: 4px;
    margin-left: 16px;
    color: #d0d0d0 !important;
    font-family: Honeywell Sans Web;
    font-size: 14px;
    font-weight: 500;
    padding-left: 30px;
  }
 
  .searchSection {
    padding: 15px 10px;
  }
  
  .searchBtn:hover {
    border: 1px solid #ffffff26 !important;
  }
  
 
  .searchBoxComponent {
    background-color: #272727 !important;
    box-shadow: none !important;
    position: relative;
    text-align: left;  }
  
  .searchBox input {
    height: 15px !important;
  }
  
  .searchBtn {
    background-color: #505050 !important;
    border-radius: 0px !important;
    border-radius: 5px !important;
    margin-left: 9px !important;
    margin-top: -2px;
    height: 36px;
  }
  
  /* added */
.classNAme em {
    color: #1792e5;
    padding-left: 10px;
    padding-top: 2px;
  }
  
  /*added*/
  .classNAme {
    display: flex;
  }

  .breadcrumbAssetMgmt {
    color: #f0f0f0;
    cursor: pointer;
  }

  .breadcrumbAssetMgmt:hover {
    color: #64C3FF;
  }

.firmware-table-header:hover {
  color: #F0F0F0 !important;
}

.group-Unassign li {
  padding: 5px 5px;
  font-size: 14px;
}